import Script from 'next/script';
import '../styles/index.css';
import React from 'react';

function MyApp({ Component, pageProps }) {
  return (
    <>
    {/* Global site tag (gtag.js) - Google Analytics */}
    <Script
      strategy="lazyOnload"
      src={`https://www.googletagmanager.com/gtag/js?id=G-YD153C7PFL`}
    />
    <Script strategy="lazyOnload" id="ga4">
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', 'G-YD153C7PFL');
      `}
    </Script>
    <Component {...pageProps} />
  </>
  )
}

export default MyApp;
